#homeIntro {
  background: linear-gradient(
    to right bottom,
    rgb(166, 249, 255) 50%,
    rgb(235, 227, 237) 50%
  );
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: none;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 80px 0 40px;
}

.homeBuzzwords {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1;
  position: relative;
  margin-bottom: 80px;
  text-shadow: -20px 20px 15px rgba(0, 0, 0, 0.3),
    0 20px 50px rgba(0, 0, 0, 0.3);
  font-weight: 900;
  letter-spacing: -2px;
  font-size: 80px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform-origin: 50% 100%;
}

.firstLine:hover span {
  animation: animate 0.8s ease-in-out;
  cursor: pointer;
  display: table-cell;
}

.middleBuzzwords:hover span {
  animation: animate 0.8s ease-in-out;
  cursor: pointer;
  display: table-cell;
}
.laughter:hover span {
  animation: animate 0.8s ease-in-out;
  cursor: pointer;
  display: table-cell;
}

@keyframes animate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.nature span:nth-child(1) {
  animation-delay: 0.1s;
}
.nature span:nth-child(2) {
  animation-delay: 0.2s;
}
.nature span:nth-child(3) {
  animation-delay: 0.3s;
}
.nature span:nth-child(4) {
  animation-delay: 0.4s;
}
.nature span:nth-child(5) {
  animation-delay: 0.5s;
}
.nature span:nth-child(6) {
  animation-delay: 0.6s;
}
.nature span:nth-child(7) {
  animation-delay: 0.7s;
}
.design span:nth-child(1) {
  animation-delay: 0.8s;
}
.design span:nth-child(2) {
  animation-delay: 0.9s;
}
.design span:nth-child(3) {
  animation-delay: 1s;
}
.design span:nth-child(4) {
  animation-delay: 1.1s;
}
.design span:nth-child(5) {
  animation-delay: 1.2s;
}
.design span:nth-child(6) {
  animation-delay: 1.3s;
}
.design span:nth-child(7) {
  animation-delay: 1.4s;
}
.middleBuzzwords span:nth-child(1) {
  animation-delay: 0.1s;
}
.middleBuzzwords span:nth-child(2) {
  animation-delay: 0.2s;
}
.middleBuzzwords span:nth-child(3) {
  animation-delay: 0.3s;
}
.middleBuzzwords span:nth-child(4) {
  animation-delay: 0.4s;
}
.middleBuzzwords span:nth-child(5) {
  animation-delay: 0.5s;
}
.middleBuzzwords span:nth-child(6) {
  animation-delay: 0.6s;
}
.middleBuzzwords span:nth-child(7) {
  animation-delay: 0.7s;
}
.middleBuzzwords span:nth-child(8) {
  animation-delay: 0.8s;
}
.middleBuzzwords span:nth-child(9) {
  animation-delay: 0.9s;
}
.middleBuzzwords span:nth-child(10) {
  animation-delay: 1s;
}
.middleBuzzwords span:nth-child(11) {
  animation-delay: 1.1s;
}
.laughter span:nth-child(1) {
  animation-delay: 0.1s;
}
.laughter span:nth-child(2) {
  animation-delay: 0.2s;
}
.laughter span:nth-child(3) {
  animation-delay: 0.3s;
}
.laughter span:nth-child(4) {
  animation-delay: 0.4s;
}
.laughter span:nth-child(5) {
  animation-delay: 0.5s;
}
.laughter span:nth-child(6) {
  animation-delay: 0.6s;
}
.laughter span:nth-child(7) {
  animation-delay: 0.7s;
}
.laughter span:nth-child(8) {
  animation-delay: 0.8s;
}
.laughter span:nth-child(9) {
  animation-delay: 0.9s;
}

.middleBuzzwords {
  color: rgb(3, 56, 58);
}
.firstLine {
  display: flex;
  margin-left: 5px;
}
.nature {
  color: rgb(65, 133, 65);
}
.design {
  color: rgb(179, 103, 205);
  margin-left: 10px;
}
.laughter {
  color: rgb(255, 254, 251);
}

.kelseysFace {
  height: 400px;
  width: auto;
  padding-top: 20px;
  border-radius: 40px;
  padding-bottom: 15px;
  max-width: 100%;
}

#about {
  background-color: rgb(166, 249, 255);
}
#projects_page {
  background-color: rgb(235, 227, 237);
}
#holla {
  background-color: rgb(166, 249, 255);
}

#h1_about {
  color: rgb(3, 56, 58);
  font-size: 60px;
  margin-bottom: 0;
  padding-top: 50px;
}
#h2_about {
  color: rgb(3, 56, 58);
  font-size: 60px;
  line-height: 0.5;
  padding-bottom: 30px;
}

#p_aboutme {
  color: rgb(14, 13, 13);
  display: block;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 50px;
}
.projects-h1 {
  padding-top: 100px;
  padding-bottom: 80px;
  font-size: 50px;
}


.w-100 {
  width: 50% !important;
  height: 70vh;
}

.carousel-control-prev{
  margin-left: 200px;
}

.carousel-control-next{
  margin-right: 200px;
}

.carousel-control-prev-icon {
  background-image: url('https://apufpel.com.br/assets/img/seta_prim.png')
}

.carousel-control-next-icon {
  background-image: url('https://apufpel.com.br/assets/img/seta_ult.png')
}

.carousel-control-prev:hover {
  transform: scale(1.8);
  transition: all ease-in-out;
}

.carousel-control-next:hover {
  transform: scale(1.8);
  transition: all ease-in-out;
}


.live {
  width: 0%;
}
.more {
  padding-top: 50px;
  white-space: nowrap;
}
.contact {
  font-size: 80px;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  
}

.resume {
  text-decoration: none;
  color: rgb(179, 103, 205);
}
.resume:hover{
  cursor: pointer;
}



@media only screen and (max-width: 667px) {
  .alignLeft {
    line-height: 80px;
    display: inline-block;
  }
  .nature {
    font-size: 50%;
  }
  .design {
    font-size: 50%;
  }
  .middleBuzzwords {
    font-size: 50%;
  }
  .laughter {
    font-size: 50%;
  }
  #about {
    height: 100px;
  }
  .kelseysFace {
    max-width: 100%;
    height: 40vh;
  }
  #h1_about {
    white-space: nowrap;
    font-size: 30px;
    padding-top: 10px;
  }
  #h2_about {
    font-size: 20px;
    padding-bottom: 20px;
    white-space: nowrap;
    line-height: 0;
  }
  #p_aboutme {
    padding-top: 100px;
    font-size: 25px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
  }
  #projects_page {
    height: 400px !important;
  }
  .carousel-control-prev{
    margin-left: 0px;
  }
  
  .carousel-control-next{
    margin-right: 0px;
  }
  
  .carousel-control-prev-icon {
    background-image: url('https://apufpel.com.br/assets/img/seta_prim.png');
  }
  
  .carousel-control-next-icon {
    background-image: url('https://apufpel.com.br/assets/img/seta_ult.png');
  }


  .w-100 {
    width: 70vw;
    height: auto;
    padding: 0px;
    padding-bottom: 5px;
  }
  
 
  .projects-h1 {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 25px;
  }
  .more {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .contact {
    font-size: 25px;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    
  }
}
