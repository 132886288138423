.theFooter {
    margin-bottom: 0;
    height: 155px;
    background-color: #f8ff1e;
}


.socialLinks {
    float: left;
    padding-left: 15px;
    padding-right: 0;
    padding-top: 55px;
    position: relative;
}

.socialLinks_list {
    list-style-type: none;
    display: inline;
 
}
.socialLinks .socialLinks__item {
    float: left;
    margin-right: 5px;
    padding: 5px;
}
.copyright {
  padding-right: 200px;
  padding-top: 90px;
 
}

@media only screen and (max-width: 667px) {
.theFooter{
    height: 200px;
}
}
