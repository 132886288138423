* {
  font-family: "Droid-Serif", "Georgia", "serif";
}

.ulHeader {
  text-align: right;
  list-style: none;
  background-color: #f8ff1e;
  padding: 40px;
  margin-bottom: 0;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.Scroll {
  opacity: 0.5;
  background-color: transparent;
  padding: 5px;
}
.Scroll:hover {
opacity: 1;
background-color:#f8ff1e ;
}
.alignleft {
  float: left;
}
.alignright {
  float: right;
  padding-top: 10px;
}
.headerName {
  color: rgb(3, 56, 58);
  font-size: 30px;
}

.navlink {
  display: inline-block;
  cursor: pointer;
  color: black;
  padding: 0 20px;
  transition: transform 0.2s;
}
.navlink:hover {
  background-color: white;
  border-radius: 10px;
  transform: scale(1.2);
  transition: ease-in-out 0.5s;
}
.about:hover {
  color: rgb(65, 133, 65);
}
.projects_page:hover {
  color: rgb(179, 103, 205);
}
.holla:hover {
  color: rgb(3, 56, 58);
}
