* {
  font-family: "Droid Serif", "Georgia", "serif";
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}
